import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationSoundService {

  notificationSound: HTMLAudioElement;

  constructor() {
    this.notificationSound = new Audio('./assets/audio/hangouts_message.mp3');
    // Agregamos la opción de bucle
    this.notificationSound.loop = true;

    // Validamos si el archivo de audio se cargó correctamente
    if (this.notificationSound.error) {
      console.error('Error al cargar el archivo de audio:', this.notificationSound.error);
      return;
    }

    // Validamos si la ruta del archivo de audio es correcta
    if (!this.notificationSound.src) {
      console.error('Ruta del archivo de audio inválida:', this.notificationSound.src);
      return;
    }

  }

  playNotificationSound() {
    this.notificationSound.play();
  }

  stopNotificationSound() {
    this.notificationSound.pause();
    this.notificationSound.currentTime = 0;
  }

}
