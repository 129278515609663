import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "./services/auth.service";
import {HttpService} from "./services/http.service";
import {Subject, Subscription} from "rxjs";
import {LocalStorageService} from "./services/local-storage.service";
import {FirestoreService} from "./services/firestore.service";
import {DataSharingService} from "./services/data-sharing.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Admin';
  private url: string;
  private isRestaurant: boolean = false;
  private isRetail: boolean = false;
  private typeBusiness = {
    isRestaurant: false,
    isRetail: false,
  }
  valor: string;
  valorSubscription: Subscription;
  sub: Subscription;
  currentMoneySubject: Subject<string> = new Subject<string>(); // define el Subject

  collectionName = 'Settings';
  collectionField = 'settings_lastupdateday';
  orderByDir: any = 'asc';
  private currentMoney: string = localStorage.getItem('currentMoney') || '';
  private tax: number = 0;
  constructor(
    private router: Router,
    private auth: AuthService,
    private service: HttpService,
    private variablesLocales: LocalStorageService,
    private firestore: FirestoreService,
    private dataSharing: DataSharingService,
  ) {
  }

  ngOnInit(): void {
    this.sub = this.firestore.search(this.collectionName, {
      'orderByName': this.collectionField,
      'orderByDir': this.orderByDir,
    }).subscribe(res1 => {
      this.getSettings();
    })

    this.valorSubscription = this.variablesLocales.getValorObservable('typeBusiness').subscribe(
      (valor: string) => {
        this.valor = valor;
      }
    );
  }


  getSettings() {
    // Se realiza una petición HTTP para obtener las configuraciones generales de la aplicación
    this.service.sendGET_OneSettingsApp('General').subscribe(res => {
      console.log('settings', res);

      // Se actualizan las propiedades isRestaurant e isRetail del objeto typeBusiness con los valores obtenidos de la respuesta
      this.typeBusiness.isRestaurant = res.isRestaurant;
      this.typeBusiness.isRetail = res.isRetail;

      // Se actualiza la variable con el valor obtenido de la respuesta
      this.currentMoney = res.currentMoney;
      this.tax = res?.tax || 0;

      // Se convierte el objeto typeBusiness a formato JSON y se almacena en el local storage
      let variableJSON = JSON.stringify(this.typeBusiness);
      localStorage.setItem('typeBusiness', variableJSON);

      // Se almacena el valor en el local storage
      localStorage.setItem('currentMoney', res.currentMoney);
      localStorage.setItem('current_tax', res.tax || 0);
      localStorage.setItem('alert_new_order_sound', res?.alert_new_order_sound || false);
      let alert_new_order_sound = res?.alert_new_order_sound.toString();

      // Se emite el valor actualizado a través del Subject definido en el servicio MySharedService
      this.dataSharing.currentMoneySubject.next(res.currentMoney);
      this.dataSharing.currentTaxSubject.next(this.tax);
      this.dataSharing.alert_new_order_sound.next(alert_new_order_sound);
    });
  }


  ngOnDestroy() {
    this.valorSubscription.unsubscribe();
    // this.sub.unsubscribe();
  }

}
