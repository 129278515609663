import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {

  public currentMoneySubject:   Subject<string>   = new Subject<string>();
  public currentTaxSubject:     Subject<number>   = new Subject<number>();
  public alert_new_order_sound: Subject<boolean>  = new Subject<boolean>();
  public is_super:              Subject<boolean>  = new Subject<boolean>();
  public uid:                   Subject<string>   = new Subject<string>();

  constructor() {
  }
}
