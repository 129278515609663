export const environment = {
  production: false,
  currency: '$',

  firebase: {
    apiKey: "AIzaSyDwEhffqjc2aR5Oj1MfBu2obelwgyFZPMM",
    authDomain: "ian-app-pe-paraiso-marino.firebaseapp.com",
    projectId: "ian-app-pe-paraiso-marino",
    storageBucket: "ian-app-pe-paraiso-marino.appspot.com",
    messagingSenderId: "299558625150",
    appId: "1:299558625150:web:2c14b4576c8e2f6a051d2f",
    measurementId: "G-6EJBGSGGMB"
  },
  googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
  urlBase: 'https://us-central1-ian-app-pe-paraiso-marino.cloudfunctions.net',
  apikey: 'c1770d54c64-c1c85549a181b-4ce9-c4862893',

};
